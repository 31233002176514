import React from 'react';
import Layout from '../components/layout/Layout';
import { graphql } from "gatsby";

const Legal = ({
  location,
  data: {
    markdownRemark : legal
  }
}) =>  {
  return (
    <Layout location={location}>
      <div className="container mx-auto px-5 max-w-2xl text-justify" dangerouslySetInnerHTML={{ __html: legal?.html }}>
      </div>
    </Layout>
  )
}

export default Legal;

export const query = graphql`
  query ($language: String!) {
    markdownRemark(
      fields: {langKey: {eq: $language}}
      frontmatter: {typeName: {eq: "cgu"}}
    ) {
      html
    }
    locales: allLocale{
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`